import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pxToRem } from "../theme/typography";
import { getAllTeamThunk } from '../store/team'
import { image_url, uploads } from "../services/endpoints";
import { setPostData } from "../store/post";
import { setLoading } from "../store/load";
import { post as postApi, teams as teamsApi } from '../services/endpoints'
import EnterTime from "../components/EnterTime";
import DenyReasonModal from "../components/DenyReasonModal";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import moment from "moment";
import lodash from 'lodash'

export default function PostingTo() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const teams = useSelector(state => state?.team?.team)
    const groups = useSelector(state => state?.post)?.groups
    const post = useSelector(state => state.post)
    const user = useSelector(state => state.auth)?.user
    const location = useLocation()
    let currentTime = moment(new Date()).unix() * 1000

    useEffect(() => {
        dispatch(getAllTeamThunk())
    }, [])

    useEffect(() => {
        if (location?.state?.groupDetail) {
            let isApproved = location.state.isAdmin
            uploadFiles([location?.state?.groupDetail?._id], isApproved ? 1 : 0)
        }
    }, [])

    const setGroups = (list = []) => {
        dispatch(setPostData({ key: "groups", value: list }))
    }

    useEffect(() => {
        if (location)
            dispatch(setPostData({ key: "isApproved", value: 0 }))
        checkIsAdmin()
    }, [groups, teams, location])

    const renderTeams = () => {
        let teamElements = []
        if (post?.isScorePost || post?.isQuizPost) {
            teamElements = teams?.map((x) => {
                if (x?.admins?.includes(user?._id)) {
                    return (
                        <>
                            <CheckComponent
                                isChecked={groups?.includes(x?._id)}
                                onPress={() => {
                                    if (groups?.includes(x?._id)) {
                                        setGroups(groups.filter(g => g != x?._id))
                                    } else {
                                        setGroups([...groups, x?._id])
                                    }
                                }}
                                profile={x?.profilePic} title={x?.name}
                            />
                            <br />
                        </>
                    )
                }
            })
        } else {
            teamElements = teams?.map((x) => {
                return (
                    <>
                        <CheckComponent
                            isChecked={groups?.includes(x?._id)}
                            onPress={() => {
                                if (groups?.includes(x?._id)) {
                                    setGroups(groups.filter(g => g != x?._id))
                                } else {
                                    setGroups([...groups, x?._id])
                                }
                            }}
                            profile={x?.profilePic} title={x?.name}
                        />
                        <br />
                    </>
                )
            })
        }
        return teamElements
    }

    const checkIsAdmin = () => {
        let fteams = teams.filter(x => groups?.includes(x?._id))
        for (let ft of fteams) {
            let admins = ft.admins
            for (let admin of admins) {
                if (admin == user?._id) {
                    dispatch(setPostData({ key: "isApproved", value: 1 }))
                }
            }
        }
    }

    const uploadFiles = async (groups, isApproved) => {
        dispatch(setLoading(true))
        let newQuizData = []
        for (let q of post.quizData) {
            let clonesQ = lodash.cloneDeep(q)
            let tempPhotos = []
            let photos = q.photos
            let urlphotos = q?.photos ? q?.photos?.filter(x => (typeof x == "string")) : []
            if (photos?.length > 0) {
                let formdata = new FormData()
                for (let p of q.photos) {
                    if (typeof p != "string") {
                        formdata.append("file", p)
                    }
                }
                let res = await hit(uploads, "post", formdata)
                if (!res.err) {
                    tempPhotos = lodash.cloneDeep(res.data)
                }
            }
            newQuizData.push({ ...clonesQ, photos: [...urlphotos, ...tempPhotos] })
        }
        if (post?.photos?.length > 0 || post?.videos?.length > 0 || post?.attachments?.length > 0) {
            let tempPhotos = []
            let tempVideo = []
            let tempThumbnails = []
            let tempAttachments = []
            let urlPhotos = post?.photos?.filter(x => (typeof x == "string"))
            let urlVideos = []
            let urlThumbnails = []
            if (post?.photos?.length > 0) {
                let formdata = new FormData()
                for (let p of post?.photos) {
                    if (typeof p != "string") {
                        formdata.append("file", p)
                    }
                }
                let res = await hit(uploads, "post", formdata)
                if (!res.err) {
                    tempPhotos = res.data
                }
            }
            if (post?.attachments?.length > 0) {
                let formdata = new FormData()
                for (let attach of post?.attachments) {
                    if (attach && attach.name) {
                        formdata.append("file", attach)
                    } else {
                        tempAttachments.push(attach)
                    }
                }
                if (formdata.has("file")) {
                    let res = await hit("/uploads/new/file", "post", formdata)
                    if (!res.err) {
                        tempAttachments = tempAttachments.concat(res.data)
                    }
                }
            }
            if (post?.videos?.length > 0) {
                let formdata = new FormData()
                let count = 0
                for (let p of post?.videos) {
                    if (typeof p != "string") {
                        formdata.append("file", p)
                    } else if (typeof p == "string") {
                        urlVideos.push(p)
                        if (post?.thumbnails[count]) {
                            urlThumbnails.push(post?.thumbnails[count])
                        }
                    }
                    count = count + 1
                }
                let res = await hit("/uploads/video/new", "post", formdata)
                if (!res.err) {
                    tempVideo = res.data.videos
                    tempThumbnails = res.data.thumbnails
                }
            }
            dispatch(setLoading(false))
            if (teams && isApproved >= 0) {
                submitForTeam(newQuizData, urlPhotos.concat(tempPhotos), urlVideos.concat(tempVideo), urlThumbnails.concat(tempThumbnails), tempAttachments, groups, isApproved)
            } else {
                submit(newQuizData, urlPhotos.concat(tempPhotos), urlVideos.concat(tempVideo), urlThumbnails.concat(tempThumbnails), tempAttachments)
            }
        } else {
            if (teams && isApproved >= 0) {
                submitForTeam(newQuizData, [], [], [], [], groups, isApproved)
            } else {
                submit(newQuizData, [], [], [], [])
            }
        }
    }

    const submitForTeam = async (quizData, photos, videos, thumbnails, attachments = [], groups, isApproved) => {
        try {
            let body = {
                "txt": post?.txt,
                "groups": groups,
                "photos": photos,
                "videos": videos,
                "thumbnails": thumbnails,
                attachments: attachments,
                "postType1": post?.isThemedPost ? "theme" : (post?.isPollPost ? "poll" : (post?.isQuizPost ? "quiz" : "normal")),
                "themeColor": post?.themeColor,
                "pollEndTime": post?.pollEndTime != "" ? moment(post?.pollEndTime).unix() * 1000 : currentTime,
                "pollOptions": post?.pollOptions,
                "isApproved": isApproved,
                "quizData": quizData,
                "quizTitle": post?.quizTitle,
                "quizDesc": post?.quizDesc,
                "isScorePost": post?.isQuizPost ? true : false,
                "disable_comments": post?.disable_comments,
                "hide_comments": post?.hide_comments,
                scoreStartTime: moment(post?.scoreStartTime).unix() * 1000,
                scoreEndTime: moment(post?.scoreEndTime).unix() * 1000,
                quizScoreCount: post?.quizScoreCount
            }
            dispatch(setLoading(true))
            if (quizData.length > 0) {
                for (let q = 0; q < quizData.length; q++) {
                    let ansOptionArr = (quizData[q]?.options) ?? [];
                    if ((new Set(ansOptionArr)).size !== ansOptionArr.length) {
                        dispatch(setLoading(false))
                        showToast("Remove duplicate answers and try again", "error")
                        return false;
                    }
                }
            }
            let res = await hit(postApi?.post, "post", body)
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Successfully post added!")
                navigate("/user/teams")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const submit = async (newQuizData, photos, videos, thumbnails, attachments = []) => {
        if (groups?.length == 0) {
            showToast("select atleast one group", "warning")
            return
        }
        try {
            let body = {
                "txt": post?.txt,
                "groups": groups,
                "photos": photos,
                "videos": videos,
                attachments,
                "thumbnails": thumbnails,
                "postType1": post?.isThemedPost ? "theme" : (post?.isPollPost ? "poll" : (post?.isQuizPost ? "quiz" : "normal")),
                "themeColor": post?.themeColor,
                "pollEndTime": post?.pollEndTime != "" ? moment(post?.pollEndTime).unix() * 1000 : currentTime,
                "pollOptions": post?.pollOptions,
                "isApproved": post?.isApproved,
                "quizData": newQuizData,
                "quizTitle": post?.quizTitle,
                "quizDesc": post?.quizDesc,
                "isScorePost": post?.isQuizPost ? true : false,
                "disable_comments": post?.disable_comments,
                "hide_comments": post?.hide_comments,
                scoreStartTime: moment(post?.scoreStartTime).unix() * 1000,
                scoreEndTime: moment(post?.scoreEndTime).unix() * 1000,
                quizScoreCount: post?.quizScoreCount
            }
            dispatch(setLoading(true))
            if (newQuizData.length > 0) {
                for (let q = 0; q < newQuizData.length; q++) {
                    let ansOptionArr = newQuizData[q]?.options;
                    if ((new Set(ansOptionArr)).size !== ansOptionArr.length) {
                        dispatch(setLoading(false))
                        showToast("Remove duplicate answers and try again", "error")
                        return false;
                    }
                }
            }
            let res = null
            if (post?.id) {
                res = await hit(postApi?.post + "/" + post?.id, "patch", body)
            } else {
                res = await hit(postApi?.post, "post", body)
            }
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                if (post?.id) {
                    showToast("Successfully post updated!")
                    navigate("/user/draft")
                } else {
                    showToast("Successfully post added!")
                    navigate("/user/home")
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Posting To</Typography>
                </Box>
                <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Groups</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontSize: pxToRem(14), marginRight: "5px" }}>Select All</Typography>
                            <Checks groups={groups} teams={teams} setGroups={setGroups} />
                        </Box>
                    </Box>
                    <br />
                    {renderTeams()}
                    <br />
                    <Button
                        onClick={() => {
                            if (post?.isScorePost) {
                                if (groups?.length == 0) {
                                    showToast("select atleast one group", "warning")
                                    return
                                }
                                navigate("/user/choose")
                            } else {
                                uploadFiles()
                            }
                        }}
                        sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }}
                        variant="contained" >
                        Post
                    </Button><br />
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, }}>
            </Box>
            <EnterTime open={open} setOpen={setOpen} />
            <DenyReasonModal open={open1} setOpen={setOpen1} />
        </Box>
    )
}

const CheckComponent = ({ title, profile, isChecked, onPress }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", p: "10px", display: "flex", alignItems: "center", background: "#0DB1D41A" }}>
            <Avatar
                src={image_url + profile}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title}</Typography>
            </Box>
            <Box onClick={() => {
                onPress()
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}

const Checks = ({ groups, teams, setGroups }) => {
    const [isChecked, setIsChecked] = useState(false)
    useEffect(() => {
        if (teams?.length == groups?.length) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [teams, groups])
    return (
        <Box onClick={() => {
            if (isChecked) {
                setGroups([])
            } else {
                setGroups(teams?.map(x => x?._id))
            }
        }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
    )
}