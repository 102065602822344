import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pxToRem } from "../theme/typography";
import { image_url, teams, uploads } from "../services/endpoints";
import { setPostData } from "../store/post";
import { setLoading } from "../store/load";
import { post as postApi, teams as teamsApi } from '../services/endpoints'
import EnterTime from "../components/EnterTime";
import DenyReasonModal from "../components/DenyReasonModal";
import showToast from "../utils/showToast";
import hit from "../services/apiManager";
import moment from "moment";
import _ from 'lodash'

export default function ChooseAccess() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const post = useSelector(state => state.post)
    const [admins, setAdmins] = useState([])
    const user = useSelector(state => state.auth?.user)
    const groups = useSelector(state => state?.post)?.groups
    const location = useLocation()

    useEffect(() => {
        getAdmins()
    }, [])

    const getAdmins = async () => {
        if (post?.groups.length == 0) {
            showToast("Group is required.", "warning")
            navigate(-1)
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(teams?.getAdmins, "post", { ids: post?.groups })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                let isAdmin = true
                let allAdmins = []
                res?.data.forEach((i, j) => {
                    let temp = i?.admins.filter(x => x?._id == user?._id)
                    if (temp.length == 0) {
                        isAdmin = false
                    } else {
                        i?.admins.forEach(e => {
                            if (!allAdmins.includes(e)) {
                                allAdmins.push(e)
                            }
                        });
                    }
                })
                if (isAdmin) {
                    let temp = []
                    let newArr = [...new Set(allAdmins?.map(obj => obj?._id))].map(id => {
                        return allAdmins.find(obj => obj?._id == id)
                    })
                    newArr.map(x => x?._id == user?._id ? temp.push(x) : null)
                    newArr.map(x => x?._id != user?._id ? temp.push(x) : null)
                    setAdmins(temp)
                } else {
                    navigate(-1)
                    showToast("You can post only in the groups where you’re an admin.")
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const setVerifyBy = (list = []) => {
        dispatch(setPostData({ key: "verifyBy", value: list }))
    }

    const uploadFiles = async () => {
        if (post?.photos?.length > 0 || post?.videos?.length > 0 || post?.attachments?.length > 0) {
            let tempPhotos = []
            let tempVideo = []
            let tempThumbnails = []
            let tempAttachments = []
            let urlPhotos = post?.photos?.filter(x => (typeof x == "string"))
            let urlVideos = []
            let urlThumbnails = []
            dispatch(setLoading(true))
            if (post?.photos?.length > 0) {
                let formdata = new FormData()
                for (let p of post?.photos) {
                    if (typeof p != "string") {
                        formdata.append("file", p)
                    }
                }
                let res = await hit(uploads, "post", formdata)
                if (!res.err) {
                    tempPhotos = res.data
                }
            }
            if (post?.videos?.length > 0) {
                let formdata = new FormData()
                let count = 0
                for (let p of post?.videos) {
                    if (typeof p != "string") {
                        formdata.append("videos", p)
                    } else if (typeof p == "string") {
                        urlVideos.push(p)
                        if (post?.thumbnails[count]) {
                            urlThumbnails.push(post?.thumbnails[count])
                        }
                    }
                    count = count + 1
                }
                let res = await hit("/uploads/video/new", "post", formdata)
                if (!res.err) {
                    tempVideo = res.data.videos
                    tempThumbnails = res.data.thumbnails
                }
            }
            if (post?.attachments?.length > 0) {
                let formdata = new FormData()
                for (let attach of post?.attachments) {
                    if (attach && attach.name) {
                        formdata.append("file", attach)
                    } else {
                        tempAttachments.push(attach)
                    }
                }
                if (formdata.has("file")) {
                    let res = await hit("/uploads/new/file", "post", formdata)
                    if (!res.err) {
                        tempAttachments = tempAttachments.concat(res.data)
                    }
                }
            }
            submit(urlPhotos.concat(tempPhotos), urlVideos.concat(tempVideo), urlThumbnails.concat(tempThumbnails), tempAttachments)
        } else {
            submit([], [], [], [])
        }
    }

    const submit = async (photos, videos, thumbnails, attachments) => {
        if (groups?.length == 0) {
            showToast("select atleast one group", "warning")
            return
        }
        let body =
        {
            "txt": post?.txt,
            "groups": groups,
            "photos": photos,
            "videos": videos,
            attachments,
            "thumbnails": thumbnails,
            "postType1": post?.isThemedPost ? "theme" : (post?.isPollPost ? "poll" : (post?.isQuizPost ? "quiz" : "normal")),
            "themeColor": post?.themeColor,
            "pollEndTime": moment(post?.pollEndTime).unix() * 1000,
            "pollOptions": post?.pollOptions,
            "isScorePost": true,
            "disable_comments": post?.disable_comments,
            "hide_comments": post?.hide_comments,
            "isApproved": post?.isApproved,
            category: post?.category,
            scoreBy: post?.scoreBy,
            verifyBy: post?.verifyBy,
            scorePoint: post?.scorePoint,
            scorePoint1: post?.scorePoint1,
            scorePoint2: post?.scorePoint2,
            scoreCount: post?.scoreCount,
            eventLocation: post?.eventLocation,
            scoreStartTime: moment(post?.scoreStartTime).unix() * 1000,
            scoreEndTime: moment(post?.scoreEndTime).unix() * 1000,
            "quizData": post?.quizData,
            "quizTitle": post?.quizTitle,
            "quizDesc": post?.quizDesc
        }
        try {
            dispatch(setLoading(true))
            let res = null
            if (post?.id) {
                res = await hit(postApi?.post + "/" + post?.id, "patch", body)
            } else {
                res = await hit(postApi?.post, "post", body)
            }
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                if (post?.id) {
                    showToast("Successfully post updated!")
                    navigate("/user/draft")
                } else {
                    showToast("Successfully post added!")
                    if (location?.state?.groupDetail?._id) {
                        navigate("/user/teams")
                    } else {
                        navigate("/user/home")
                    }
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Choose Access</Typography>
                </Box>
                <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Admin List</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontSize: pxToRem(14), marginRight: "5px" }}>Select All</Typography>
                            <Checks verifyBy={post?.verifyBy} admins={admins} setVerifyBy={setVerifyBy} />
                        </Box>
                    </Box>
                    <br />
                    {_.sortBy(admins, [function (o) { return String(o?.lastName).toLowerCase() }])?.map(x => (
                        <CheckComponent email={x?.email} isChecked={post?.verifyBy?.includes(x?._id)} onPress={() => {
                            if (post?.verifyBy?.includes(x?._id)) {
                                setVerifyBy(post?.verifyBy?.filter(g => g != x?._id))
                            } else {
                                setVerifyBy([...post?.verifyBy, x?._id])
                            }
                        }} profile={x?.profilePic} title={x?.firstName + " " + x?.lastName + (x?._id == user?._id ? " (Myself)" : "")} />
                    ))}
                    <br />
                    <br />
                    <Button 
                        onClick={() => {
                            if (post?.verifyBy?.length == 0) {
                                showToast("select atleast one user!", "warning")
                                return
                            }
                            uploadFiles()
                        }} 
                        sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} 
                        variant="contained">
                            Post
                    </Button><br />
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, }}>
            </Box>
            <EnterTime open={open} setOpen={setOpen} />
            <DenyReasonModal open={open1} setOpen={setOpen1} />
        </Box>
    )
}

const CheckComponent = ({ title, profile, isChecked, onPress, email }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", p: "10px", marginTop: "10px", display: "flex", alignItems: "center", background: "#0DB1D41A" }}>
            <Avatar
                src={image_url + profile}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{email}</Typography>

            </Box>
            <Box onClick={() => {
                onPress()
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}

const Checks = ({ setVerifyBy, verifyBy, admins }) => {
    const [isChecked, setIsChecked] = useState(false)
    useEffect(() => {
        if (admins?.length == verifyBy?.length) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [admins, verifyBy])
    return (
        <Box onClick={() => {
            if (isChecked) {
                setVerifyBy([])
            } else {
                setVerifyBy(admins?.map(x => x?._id))
            }
        }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
    )
}